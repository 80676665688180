<template>
  <div class="governance amla" id="amla">
    <div class="ui grid container">
      <div class="intro-content amla">
        <h2>Anti-Money Laundering Compliance</h2>
        <div class="paragraph">
          <p>TayoCash, as a financial institution regulated by the Bangko Sentral ng Pilipinas and registered with the Anti-Money Laundering Council, adopts a comprehensive and risk-based Money Laundering and Terrorist Financing Prevention Program (MLTFPP). This is supplemented by internal policies, controls, and procedures to protect the integrity and confidentiality of our customer accounts, while preventing the use of the TayoCash system, platform, facilities, and services for money laundering and terrorist financing activities.</p>

          <p>We are committed to ensuring high standards in:
            <ul>
              <li>Establishing the identity of our customers and true owners or principals and agents of accounts;</li>
              <li>Producing and safe-keeping identification and transaction records;</li>
              <li>Reporting suspicious transactions to the competent authorities;</li>
              <li>Enhancing relationships and cooperation with the authorities; and</li>
              <li>Carrying out preventive procedures, training, pre-employment screening, and internal controls.</li>
            </ul>
          </p>
        </div>

        <div class="buttons">
          <a href="/privacypolicy" class="yellow">PRIVACY POLICY</a>
          <a href="/erm" class="white">ENTERPRISE RISK MANAGEMENT</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'amla'
}
</script>

<style scoped lang='scss'></style>
